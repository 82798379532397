import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const About = () => {
    return (
        <Layout>
            <div id='faq container' className='pt-32 flex flex-col items-center w-screen'>
                <h2 className='text-4xl text-center'>Algo sobre Nosotros</h2>
                <div className='p-6 mt-10 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='mt-2 text-lg text-center tracking-normal leading-5'>Somos una empresa establecida en Guadalajara, Jalisco. Nuestro principal objetivo es apoyar a que las empresas se conviertan en un vinculo de ayuda para las familias, ya sea utilizando nuestras despensas como donativo o como regalo, en DespensaTotal contamos con despensas completas y económicas, siemrpre manteniendo altos estándares de calidad de cada uno de los productos que componen nuestras despensas*. Buscando con ello ser reconocidos como una empresa lider en el pais, acercando lo mejor para las familias mexicanas.</p>
                </div>
            </div>
        </Layout>
    );
};

export default About;

export function Head() {
    return(
      <Seo />
    );
  }